import { PlayTrack } from '../mixer/Timeline'
import { SegmentType } from '../mixer/Track'
import APC40MkII from './APC40MkII'
import Clock from '../mixer/Clock'
import Controller from '../mixer/Controller'

function NormalizeDb(db: number) {
  const scaled = (db + 26) / 26
  return (scaled + 1) / 2
}

export default class MidiController {
  controller: Controller
  device = new APC40MkII()
  lastBeat = -1
  grid = new Uint8Array(40)
  knobs = new Uint8Array(8)
  buttons = new Uint8Array(32)

  constructor(controller: Controller) {
    this.controller = controller
  }

  async start() {
    return this.device.start()
  }

  stop() {
    this.device.stop()
  }

  render(tick: number) {
    if (!this.device.ready) return

    const absBeat = Clock.TickToBeat(tick)
    if (absBeat === this.lastBeat) return
    this.lastBeat = absBeat

    const fillGrid = (grid: Uint8Array, offset: number, play: PlayTrack) => {
      const beat = absBeat - play.start + play.offsetBeat
      const startBar = Math.floor(play.offsetBeat / 4)
      for (let x = 0; x < 8; x++) {
        const bar = Math.floor(beat / 4) + x
        const absMidBarBeat = bar * 4 + 2 + play.start - play.offsetBeat
        const phrase = Math.floor(bar / 8)
        const phraseBar = bar - phrase * 8
        const gain = this.controller.timeline.gain(absMidBarBeat, play.channel)
        const vocalScore = play.track.metadata.vocals[bar] * gain
        const segment = play.track.findSegment(bar)
        const high = segment?.type === SegmentType.High

        if (gain === 0) grid[offset + x] = 0
        else if (bar === startBar) grid[offset + x] = 25
        else if (segment?.bar === bar) grid[offset + x] = 57
        else if (phraseBar === 0) grid[offset + x] = 49
        else if (high) {
          if (vocalScore >= 1) grid[offset + x] = 5
          else if (vocalScore >= 0) grid[offset + x] = 6
          else grid[offset + x] = 7
        } else {
          if (vocalScore >= 1) grid[offset + x] = 41
          else if (vocalScore >= 0) grid[offset + x] = 42
          else grid[offset + x] = 43
        }
      }
    }

    const setKnobs = (knobs: Uint8Array, offset: number, play: PlayTrack) => {
      const channel = this.controller.channels[play.channel]
      knobs[offset + 0] = channel.preGain.gain.value * 127
      knobs[offset + 1] = channel.gain.gain.value * 127
      knobs[offset + 2] = NormalizeDb(channel.lowEq.gain.value) * 127
      knobs[offset + 3] = NormalizeDb(channel.highEq.gain.value) * 127
    }

    const playA = this.controller.timeline.track(absBeat, 0)
    const playB = this.controller.timeline.track(absBeat, 1)

    if (playA) fillGrid(this.grid, 0, playA)
    if (playB) fillGrid(this.grid, 16, playB)
    this.device.setGrid(this.grid)

    if (playA) setKnobs(this.knobs, 0, playA)
    if (playB) setKnobs(this.knobs, 4, playB)
    this.device.setDeviceRings(this.knobs)

    // Show the current beat in the 8-bar phrase
    const phrase = Math.floor(absBeat / 32)
    const phraseBeat = absBeat - phrase * 32
    this.buttons.fill(0)
    this.buttons[phraseBeat] = 1
    this.device.setTrackButtons(this.buttons)
  }
}
