import { AudioBuffer } from 'standardized-audio-context'

// Example output: 01:04.48
export function FormatTime(time: number) {
  time *= 1000.0
  const minutes = Math.floor(time / (1000 * 60))
  const seconds = Math.floor((time / 1000) % 60)
  const deciseconds = Math.floor((time % 1000) / 10)

  const minStr = minutes.toString().padStart(2, '0')
  const secStr = seconds.toString().padStart(2, '0')
  const decStr = deciseconds.toString().padStart(2, '0')
  return `${minStr}:${secStr}.${decStr}`
}

export function BufferToWaveform(buffer: AudioBuffer, width: number) {
  const array = StereoToMono(buffer.getChannelData(0))
  const length = 64
  const rmses = []
  for (let i = 0; i < width; i++) {
    const offsetStart = Math.floor(Scale(i, 0, width, 0, array.length - length))
    const offsetEnd = offsetStart + length
    let sum = 0
    for (let s = offsetStart; s < offsetEnd; s++) {
      sum += Math.pow(array[s], 2)
    }
    const rms = Math.sqrt(sum / length)
    rmses[i] = rms
  }
  const max = Math.max(...rmses)
  return rmses.map((v) => Scale(Math.pow(v, 0.8), 0, max, 0, 1))
}

function Scale(x: number, inLow: number, inHigh: number, outLow: number, outHigh: number) {
  if (x === Infinity || x === -Infinity) return x
  return ((x - inLow) * (outHigh - outLow)) / (inHigh - inLow) + outLow
}

function StereoToMono(channels: Float32Array | Float32Array[]) {
  if (!Array.isArray(channels)) return channels
  const mono = new Float32Array(channels[0].length)
  for (let i = 0; i < channels[0].length; i++) {
    for (let c = 0; c < channels.length; c++) {
      mono[i] += channels[c][i]
    }
    mono[i] /= channels.length
  }
  return mono
}
