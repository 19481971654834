import log from 'loglevel'
import { AudioContext, IAudioContext } from 'standardized-audio-context'
import TemporalValue from './TemporalValue'

export type BeatCallback = (time: number, beat: number) => void

export default class Clock {
  // Pulses per quarter note, our atomic unit of time. See
  // <https://en.wikipedia.org/wiki/Pulses_per_quarter_note>
  static PPQ = 64

  static BeatToTick(beat: number) {
    return beat * Clock.PPQ
  }

  static TickToBeat(tick: number) {
    return Math.floor(tick / Clock.PPQ)
  }

  toleranceEarly = 0.1
  tempo = new TemporalValue(0)
  onBeat: BeatCallback
  context?: IAudioContext
  beat = { time: 0, value: 0 }
  startTime = 0
  endTime = 0
  running = false
  timeoutId?: ReturnType<typeof setTimeout>

  constructor(onBeat: BeatCallback, context?: IAudioContext) {
    this.onBeat = onBeat
    if (context) this.context = context
  }

  get currentTempo() {
    return this.context ? this.tempo.valueAt(this.currentTick) : 0
  }

  get currentBeat() {
    return this.beat.value
  }

  get currentTick() {
    if (!this.context) return 0
    const newTicks = Math.floor((this.context.currentTime - this.beat.time) / Clock.PPQ)
    return this.beat.value * Clock.PPQ + newTicks
  }

  init() {
    this.context = new AudioContext({ latencyHint: 'playback' })
  }

  async start() {
    if (!this.context) throw new Error('Cannot start() clock before init()')
    if (this.context.state !== 'running') await this.context.resume()
    this.beat = { time: 0, value: 0 }
    this.startTime = 0
    this.endTime = 0
    this.running = true
    this._stopBeat()
    this.beatCallback()
  }

  stop() {
    this.endTime = this.context ? this.context.currentTime : 0
    this.running = false
    this._stopBeat()
  }

  _stopBeat() {
    if (this.timeoutId !== undefined) {
      clearTimeout(this.timeoutId)
      this.timeoutId = undefined
    }
  }

  now() {
    if (!this.running || !this.context) return this.endTime - this.startTime
    return this.context.currentTime - this.startTime
  }

  tickToTime(tick: number) {
    const curTick = Clock.BeatToTick(this.beat.value)

    if (tick === curTick) return this.beat.time

    if (tick < curTick) {
      log.warn(`Tick ${tick} is behind playhead at ${curTick}, extrapolating in the past`)
      const bpm = this.tempo.valueAt(curTick)
      const tickDuration = (1.0 / Clock.PPQ) * (60.0 / bpm)
      return this.beat.time - (curTick - tick) * tickDuration
    }

    // Iterate tick by tick, accumulating seconds until we reach the target tick
    let seconds = 0.0
    for (let t = curTick; t < tick; t++) {
      const bpm = this.tempo.valueAt(t)
      const tickDuration = (1.0 / Clock.PPQ) * (60.0 / bpm)
      seconds += tickDuration
    }
    return this.beat.time + seconds
  }

  tickToAbsTime(tick: number) {
    return this.tickToTime(tick) + this.startTime
  }

  beatToTime(beat: number) {
    return this.tickToTime(Clock.BeatToTick(beat))
  }

  beatToAbsTime(beat: number) {
    return this.beatToTime(beat) + this.startTime
  }

  beatCallback = () => {
    if (!this.context) return

    const absNow = this.context.currentTime
    if (this.startTime === 0) this.startTime = absNow
    const now = absNow - this.startTime
    const prev = this.beat.time

    let skipped = -1
    while (this.beat.time < now) {
      const nextBeat = this.beat.value + 1
      this.beat = { time: this.beatToTime(nextBeat), value: nextBeat }
      skipped++
    }

    if (skipped > 0) {
      log.warn(`Skipped ${skipped} beat(s) from ${prev} to ${now}`)
    }

    this.onBeat(this.beat.time, this.beat.value)

    const nextBeatTime = this.beatToTime(this.beat.value + 1)
    const offset = Math.max(0, nextBeatTime - this.toleranceEarly - now)
    this.timeoutId = setTimeout(this.beatCallback, offset * 1000)
  }
}
