import log from 'loglevel'
import PRNG from './PRNG'
import { GetCloselyRelatedKeys, GetVaguelyRelatedKeys, Key } from './Keys'
import { Track } from './Track'

type JSONMap = { [k: string]: any }

export default class Library {
  rng: PRNG
  tracks: Track[] = []
  tracksByName = new Map<string, Track>()
  tracksByKey = new Map<Key, Track[]>()

  constructor(rng: PRNG, url?: string) {
    this.rng = rng
    if (url) this.load(url)
  }

  get loaded() {
    return this.tracks.length > 0
  }

  findTrack(name: string) {
    return this.tracksByName.get(name)
  }

  findTracksInKey(key: Key): Track[] {
    let tracks: Track[] = []
    for (let k of GetCloselyRelatedKeys(key)) {
      const tracksInKey = this.tracksByKey.get(k)
      if (tracksInKey) tracks = tracks.concat(tracksInKey)
    }
    return tracks
  }

  findTracksNearKey(key: Key): Track[] {
    let tracks: Track[] = []
    for (let k of GetVaguelyRelatedKeys(key)) {
      const tracksInKey = this.tracksByKey.get(k)
      if (tracksInKey) tracks = tracks.concat(tracksInKey)
    }
    return tracks
  }

  randomTrack() {
    return this.rng.nextArrayItem(this.tracks)
  }

  async load(url: string) {
    log.debug(`Loading library from ${url}`)
    await fetch(url)
      .then((response) => response.json())
      .then((db) => this.loadData(db))

    // Download onsets for all the tracks
    await Promise.all(this.tracks.map((t) => t.loadOnsets()))
  }

  loadData(db: JSONMap) {
    this.tracks.length = 0
    this.tracksByName.clear()
    this.tracksByKey.clear()
    if (!Array.isArray(db['tracks'])) throw new Error(`Database is missing tracks list`)
    for (const entry of db['tracks']) {
      const track = new Track(entry)
      const key = track.metadata.key
      if (this.tracksByName.has(track.name)) log.error(`Loading duplicate track "${track.name}"`)
      if (!this.tracksByKey.has(key)) this.tracksByKey.set(key, [])

      this.tracks.push(track)
      this.tracksByName.set(track.name, track)
      this.tracksByKey.get(key)?.push(track)
    }
    log.info(`Loaded ${this.tracks.length} track(s) into the library`)
  }
}
