import log from 'loglevel'
import { AudioBuffer, IAudioContext } from 'standardized-audio-context'
import { Key } from './Keys'

export enum SegmentType {
  Low = 'L',
  High = 'H',
}

export type SegmentMarker = {
  bar: number
  type: SegmentType
}

export type TrackMetadata = {
  url: string
  semitonesUp: string[]
  semitonesDown: string[]
  onsetsUrl: string
  artist: string
  title: string
  key: Key
  duration: number
  phase: number
  tempo: number
  replayGain: number
  themeDescriptor: number[]
  beats: number[]
  downbeats: number[]
  vocals: number[]
  segments: SegmentMarker[]
}

export class Track {
  metadata: TrackMetadata
  buffer?: AudioBuffer
  onsets?: Float32Array
  loadedSemitoneOffset = 0
  loading = false

  constructor(entry: any) {
    this.metadata = entry
  }

  get name(): string {
    return `${this.metadata.artist} - ${this.metadata.title}`
  }

  getUrl(semitoneOffset: number): string {
    if (semitoneOffset === 0) return this.metadata.url
    const index = Math.abs(semitoneOffset) - 1
    const array = semitoneOffset > 0 ? this.metadata.semitonesUp : this.metadata.semitonesDown
    if (index >= array.length)
      throw new Error(`"${this.name}" missing pitch bend, ${semitoneOffset} > ${array.length}`)
    return array[index]
  }

  audioLoaded(semitoneOffset: number): boolean {
    return this.buffer !== undefined && this.loadedSemitoneOffset === semitoneOffset
  }

  normalizedGain(target = -10): number {
    return Math.pow(10, -(target - this.metadata.replayGain) / 10.0 / 2.0)
  }

  findSegment(bar: number): SegmentMarker | undefined {
    const index = this.findSegmentIndex(bar)
    return index !== undefined ? this.metadata.segments[index] : undefined
  }

  findSegmentIndex(bar: number): number | undefined {
    const segments = this.metadata.segments
    let start = 0
    let end = segments.length - 1

    while (start <= end) {
      const mid = Math.floor((start + end) / 2)
      if (segments[mid].bar <= bar && (mid === end || segments[mid + 1].bar > bar))
        return mid

      if (segments[mid].bar < bar) start = mid + 1
      else end = mid - 1
    }

    return undefined
  }

  async loadTrack(context: IAudioContext, semitoneOffset: number) {
    // FIXME: Support loading different semitoneOffsets without ignoring/discarding other offsets
    if (this.loading || this.audioLoaded(semitoneOffset)) return

    this.loading = true
    this.loadedSemitoneOffset = semitoneOffset
    this.buffer = undefined

    const url = this.getUrl(semitoneOffset)
    log.debug(`Loading track "${this.name}" (SEMI ${semitoneOffset}) from ${url}`)
    const res = await fetch(url, { mode: 'cors', cache: 'force-cache' })
    if (!res.ok) throw new Error(`Failed to load track from ${url}`)
    const audioBuffer = await res.arrayBuffer()
    this.buffer = await context.decodeAudioData(audioBuffer)
    this.loading = false
    log.debug(`Loaded "${this.name}" track (${this.buffer.duration} sec)`)
  }

  async loadOnsets() {
    if (this.onsets) return

    const res = await fetch(this.metadata.onsetsUrl, { mode: 'cors', cache: 'force-cache' })
    if (!res.ok) throw new Error(`Failed to load onsets from ${this.metadata.onsetsUrl}`)
    const onsetsBuffer = await res.arrayBuffer()
    this.onsets = new Float32Array(onsetsBuffer)
    log.debug(`Loaded "${this.name}" onsets (${this.onsets.length})`)
  }
}
