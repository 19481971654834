import log from 'loglevel'
import Channel from './Channel'
import Clock from './Clock'
import { Crossfade, TransitionProfile } from './Crossfade'
import { DJ } from './DJ'
import Library from './Library'
import PRNG from './PRNG'
import * as Timeline from './Timeline'

const CHANNELS = 2
const SEED = Date.now()

export default class Controller {
  static Initialized = false

  clock: Clock
  library = new Library(new PRNG(SEED))
  dj = new DJ(new PRNG(SEED), this.library)
  channels: Channel[] = []
  lastChannel?: number
  lastAbsBar = 0
  inTheMix = false
  timeline = new Timeline.Timeline()

  constructor() {
    this.clock = new Clock(this.beatHandler)
  }

  get running() {
    return this.clock.running
  }

  get bpm() {
    return this.clock.currentTempo
  }

  get tick() {
    return this.clock.currentTick
  }

  get beat() {
    return this.clock.beat.value
  }

  get measure() {
    return Math.floor(this.clock.currentBeat / 4)
  }

  get time() {
    return this.clock.now()
  }

  get context() {
    if (!this.clock.context) throw new Error(`AudioContext is not initialized`)
    return this.clock.context
  }

  async load(libraryUrl: string) {
    await this.library.load(libraryUrl)
  }

  async start() {
    if (!this.library.loaded) {
      return log.error('Cannot start, library is not loaded yet')
    }

    if (this.clock.context) this.dj = new DJ(new PRNG(Date.now()), this.library)
    this.inTheMix = false
    this.lastChannel = undefined
    this.lastAbsBar = 0
    this.timeline = new Timeline.Timeline()

    if (!this.clock.context) this.clock.init()

    this.channels.length = 0
    for (let i = 0; i < CHANNELS; i++) {
      this.channels.push(new Channel(this.context, `${i}`))
    }

    const trackCount = this.library.tracks.length
    if (trackCount < 2) {
      return log.error(`Cannot start, library only loaded ${trackCount} track(s)`)
    }

    // Clear any previous schedule
    this.clock.beat = { time: 0, value: 0 }
    this.clock.tempo.clear()
    this.timeline.clear()
    for (let channel of this.channels) {
      channel.clearScheduledValues()
      channel.gain.gain.value = 0
    }

    // Start with only the first channel gain turned up
    this.channels[0].gain.gain.value = 1

    // Schedule the starting track and wait for it to load
    const mix = this.dj.firstMix()
    await mix.transition.track.loadTrack(this.context, 0)
    this.mixToTimeline(mix, this.nextChannel())

    // Start playback
    await this.clock.start()
  }

  stop() {
    if (this.clock) this.clock.stop()
    for (let channel of this.channels) {
      channel.stop()
    }
  }

  nextChannel() {
    if (this.lastChannel === undefined) this.lastChannel = -1
    this.lastChannel = (this.lastChannel + 1) % this.channels.length
    return this.lastChannel
  }

  mixToTimeline(mix: Crossfade, nextChannel: number, prevChannel?: number) {
    if (prevChannel === nextChannel) throw new Error(`nextChannel and prevChannel = ${nextChannel}`)
    const t = mix.transition
    const startBar = this.lastAbsBar + t.primaryBar - t.fadeInBars
    const startBeat = startBar * 4
    log.info(`Adding "${t.track.name}" to the timeline (${prevChannel} -> ${nextChannel})`)

    const addCurves = (channel: number, profile: TransitionProfile) => {
      for (let i = 1; i < profile.gain.length; i++) {
        const a = profile.gain[i - 1]
        const b = profile.gain[i]
        const aBeat = startBeat + a.beat
        const bBeat = startBeat + b.beat
        this.timeline.add(new Timeline.Volume(channel, aBeat, bBeat, a.value, b.value))
      }

      for (let i = 1; i < profile.high.length; i++) {
        const a = profile.high[i - 1]
        const b = profile.high[i]
        const aBeat = startBeat + a.beat
        const bBeat = startBeat + b.beat
        this.timeline.add(new Timeline.High(channel, aBeat, bBeat, a.value, b.value))
      }

      for (let i = 1; i < profile.low.length; i++) {
        const a = profile.low[i - 1]
        const b = profile.low[i]
        const aBeat = startBeat + a.beat
        const bBeat = startBeat + b.beat
        this.timeline.add(new Timeline.Low(channel, aBeat, bBeat, a.value, b.value))
      }
    }

    const fadeEndBar = startBar + t.fadeInBars + t.fadeOutBars
    const fadeEndBeat = fadeEndBar * 4

    // endBeat is a placeholder, the track ending will be modified when the next mix is created
    const endBar = startBar + t.track.metadata.downbeats.length - t.secondaryBar
    const endBeat = endBar * 4

    const offsetBeat = (t.secondaryBar - t.fadeInBars) * 4
    const offsetSec = t.track.metadata.beats[offsetBeat]

    this.timeline.add(
      new Timeline.PlayTrack(
        nextChannel,
        startBeat,
        fadeEndBeat,
        endBeat,
        t.track,
        offsetBeat,
        offsetSec,
        t.semitoneOffset
      )
    )
    addCurves(nextChannel, mix.intro)
    if (prevChannel !== undefined) addCurves(prevChannel, mix.outro)

    // BPM curve
    const nextTempo = t.track.metadata.tempo
    if (prevChannel !== undefined) {
      if (!t.prevTrack) throw new Error(`prevChannel=${prevChannel} but prevTrack unset`)
      const prevTempo = t.prevTrack.metadata.tempo
      const endBeat = (startBar + t.fadeInBars + t.fadeOutBars) * 4
      const prevRateEnd = nextTempo / prevTempo
      const nextRateBegin = prevTempo / nextTempo

      this.clock.tempo.setValueAt(Clock.BeatToTick(startBeat), prevTempo)
      this.clock.tempo.setValueAt(Clock.BeatToTick(endBeat), nextTempo)

      // NOTE: These have to be added after the Timeline.PlayTrack events, otherwise the
      // player we want to change playbackRate on won't be initialized yet
      this.timeline.add(new Timeline.PlayRate(prevChannel, startBeat, endBeat, 1, prevRateEnd))
      this.timeline.add(new Timeline.PlayRate(nextChannel, startBeat, endBeat, nextRateBegin, 1))
    } else {
      this.clock.tempo.setValueAt(Clock.BeatToTick(startBeat), nextTempo)
    }

    this.lastAbsBar = startBar + t.fadeInBars - t.secondaryBar
  }

  beatHandler = (time: number, beat: number) => {
    if (!this.clock) return

    // log.info(`time=${time}, beat=${Math.floor(beat / 4)}:${beat - measure * 4} (${beat})`)

    const nextMix = this.dj.mixCandidates[this.dj.candidateIndex]
    const t = nextMix.transition
    if (this.inTheMix) {
      // Check if we have exited the mix
      const exitBar = this.lastAbsBar + t.secondaryBar + t.fadeOutBars
      const secondsUntilEnd = this.clock.beatToTime(exitBar * 4) - time
      if (secondsUntilEnd <= 0.0) {
        log.info(`Finished mixing into "${t.track.name}"`)
        this.inTheMix = false
        this.dj.nextMix()
      }
    } else {
      // Check if we should start loading the next track
      const enterBar = this.lastAbsBar + t.primaryBar - t.fadeInBars
      const secondsUntilMix = this.clock.beatToTime(enterBar * 4) - time
      if (secondsUntilMix <= 30.0 && !t.track.loading && !t.track.audioLoaded(t.semitoneOffset))
        t.track.loadTrack(this.context, t.semitoneOffset)

      // Check if we are entering a mix
      if (secondsUntilMix <= 2.0) {
        log.info(`Mixing into "${t.track.name}"`)
        const lastCh = this.lastChannel
        this.mixToTimeline(nextMix, this.nextChannel(), lastCh)
        this.inTheMix = true
      }
    }

    // Retrieve and remove all timeline events occuring on or before the next
    // beat
    const events = this.timeline.popEvents(beat + 1)
    for (const event of events) {
      event.startTime = this.clock.beatToTime(event.start)
      const startAbsTime = event.startTime + this.clock.startTime

      if (event instanceof Timeline.PlayTrack) {
        const channel = this.channels[event.channel]
        // log.debug(
        //   `Play(ch=${event.channel}, start=${event.start}, offset=${event.offsetSec},` +
        //     ` name=${event.track.name})`
        // )
        channel.cue(event.track, event.semitoneOffset)
        channel.play(startAbsTime, event.offsetSec)
      } else if (event instanceof Timeline.PlayRate) {
        const channel = this.channels[event.channel]
        const startValue = event.value.start.value
        const endValue = event.value.end.value
        const endAbsTime = this.clock.beatToAbsTime(event.end)
        const duration = endAbsTime - startAbsTime - Number.EPSILON
        // log.debug(
        //   `PlayRate(ch=${event.channel}, beats=[${event.value.start.beat}, ` +
        //     `${event.value.end.beat}], values=[${startValue}, ${endValue}])`
        // )
        if (!channel.player)
          throw new Error(`Can't set PlayRate on uninitialized channel ${event.channel}`)

        channel.player.playbackRate.cancelScheduledValues(startAbsTime)

        if (event.value.beats > 0) {
          const curve = [startValue, endValue]
          channel.player.playbackRate.linearRampToValueAtTime(startValue, startAbsTime)
          if (startValue !== endValue)
            channel.player.playbackRate.setValueCurveAtTime(curve, startAbsTime, duration)
        } else {
          channel.player.playbackRate.setValueAtTime(endValue, endAbsTime)
        }
      } else if (event instanceof Timeline.Volume) {
        const channel = this.channels[event.channel]
        const startValue = event.value.start.value
        const endValue = event.value.end.value
        const endAbsTime = this.clock.beatToAbsTime(event.end)
        const duration = endAbsTime - startAbsTime - Number.EPSILON
        // log.debug(
        //   `Volume(ch=${event.channel}, beats=[${event.value.start.beat}, ` +
        //     `${event.value.end.beat}], values=[${startValue}, ${endValue}])`
        // )
        channel.gain.gain.cancelScheduledValues(startAbsTime)

        if (event.value.beats > 0) {
          channel.gain.gain.linearRampToValueAtTime(startValue, startAbsTime)
          if (startValue !== endValue)
            channel.gain.gain.setValueCurveAtTime([startValue, endValue], startAbsTime, duration)
        } else {
          channel.gain.gain.setValueAtTime(endValue, endAbsTime)
        }
      } else if (event instanceof Timeline.High) {
        const channel = this.channels[event.channel]
        const startValue = event.value.start.value
        const endValue = event.value.end.value
        const startDb = -(26 * (1 - startValue))
        const endDb = -(26 * (1 - endValue))
        const endAbsTime = this.clock.beatToAbsTime(event.end)
        const duration = endAbsTime - startAbsTime - Number.EPSILON
        // log.debug(
        //   `High(ch=${event.channel}, beats=[${event.value.start.beat}, ` +
        //     `${event.value.end.beat}], values=[${startDb}, ${endDb}])`
        // )
        channel.highEq.gain.cancelScheduledValues(startAbsTime)

        if (event.value.beats > 0) {
          channel.highEq.gain.linearRampToValueAtTime(startDb, startAbsTime)
          if (startDb !== endDb)
            channel.highEq.gain.setValueCurveAtTime([startDb, endDb], startAbsTime, duration)
        } else {
          channel.highEq.gain.setValueAtTime(endDb, endAbsTime)
        }
      } else if (event instanceof Timeline.Low) {
        const channel = this.channels[event.channel]
        const startValue = event.value.start.value
        const endValue = event.value.end.value
        const startDb = -(26 * (1 - startValue))
        const endDb = -(26 * (1 - endValue))
        const endAbsTime = this.clock.beatToAbsTime(event.end)
        const duration = endAbsTime - startAbsTime - Number.EPSILON
        // log.debug(
        //   `Low(ch=${event.channel}, beats=[${event.value.start.beat}, ` +
        //     `${event.value.end.beat}], values=[${startDb}, ${endDb}])`
        // )
        channel.lowEq.gain.cancelScheduledValues(startAbsTime)

        if (event.value.beats > 0) {
          channel.lowEq.gain.linearRampToValueAtTime(startDb, startAbsTime)
          if (startDb !== endDb)
            channel.lowEq.gain.setValueCurveAtTime([startDb, endDb], startAbsTime, duration)
        } else {
          channel.lowEq.gain.setValueAtTime(endDb, endAbsTime)
        }
      } else {
        log.error(`Unknown event type ${event}`)
      }
    }
  }
}
