import log from 'loglevel'
import { Track } from './Track'
import { IGainNode, IAudioBufferSourceNode, IAudioContext, IBiquadFilterNode } from 'standardized-audio-context'

const LOW_CUTOFF = 150
const HIGH_CUTOFF = 10000

export default class Channel {
  context: IAudioContext
  name: string
  // meter: Tone.Meter
  preGain: IGainNode<IAudioContext>
  gain: IGainNode<IAudioContext>
  lowEq: IBiquadFilterNode<IAudioContext>
  highEq: IBiquadFilterNode<IAudioContext>
  playing = false
  player?: IAudioBufferSourceNode<IAudioContext>
  loading?: Promise<void>
  private _track?: Track

  constructor(context: IAudioContext, name: string) {
    this.context = context
    this.name = name

    // this.meter = new Tone.Meter({ smoothing: 0.95, normalRange: true })
    this.gain = this.context.createGain()
    this.gain.gain.value = 0
    this.gain.connect(this.context.destination)

    this.lowEq = this.context.createBiquadFilter()
    this.lowEq.type = 'lowshelf'
    this.lowEq.frequency.value = LOW_CUTOFF
    this.lowEq.gain.value = -52
    this.lowEq.connect(this.gain)

    this.highEq = this.context.createBiquadFilter()
    this.highEq.type = 'highshelf'
    this.highEq.frequency.value = HIGH_CUTOFF
    this.highEq.gain.value = -52
    this.highEq.connect(this.lowEq)

    this.preGain = this.context.createGain()
    this.preGain.gain.value = 0
    this.preGain.connect(this.highEq)
  }

  get track() {
    return this._track
  }

  async cue(track: Track, semitoneOffset: number) {
    this._track = track

    log.debug(`Cueing '${track.name}' into channel ${this.name}`)
    this.stop()
    this.player = this.context.createBufferSource()
    this.player.loop = false
    this.player.connect(this.preGain)

    const rg = track.normalizedGain()
    this.preGain.gain.value = rg

    if (!track.audioLoaded(semitoneOffset)) {
      this.loading = track.loadTrack(this.context, semitoneOffset)
      await this.loading
      this.loading = undefined
    }

    if (!track.buffer) throw new Error(`Failed to load track "${track.name}"`)
    this.player.buffer = track.buffer
    log.info(`Cued '${track.name}' into channel ${this.name} (gain ${rg})`)
  }

  clearScheduledValues() {
    this.gain.gain.cancelScheduledValues(0)
    this.preGain.gain.cancelScheduledValues(0)
    this.lowEq.gain.cancelScheduledValues(0)
    this.highEq.gain.cancelScheduledValues(0)
    this.player?.playbackRate.cancelScheduledValues(0)
  }

  unload() {
    this.stop()
    this._track = undefined
  }

  async play(time?: number, offset?: number) {
    if (!this.player) throw new Error(`Cannot play() channel before load()`)
    if (this.playing) throw new Error(`Channel ${this.name} is already playing`)

    if (this.loading) {
      log.warn(`Track "${this.track?.name}" is not loaded, waiting`)
      await this.loading
      log.warn(`Finished late loading of "${this.track?.name}"`)
    }

    this.player.start(time, offset)
    this.playing = true
  }

  stop() {
    if (!this.playing) return
    this.player?.stop()
    this.player?.disconnect()
    this.playing = false
  }
}
