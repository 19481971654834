import Vector from './Vector'

const COEFFICIENTS = new Vector([
  1, // Key
  1, // MixType
  1, // Theme
  1, // Onsets
  1, // Vocals
  1, // NextSegments
])

export enum ScoreField {
  Key = 0,
  MixType = 1,
  Theme = 2,
  Onsets = 3,
  Vocals = 4,
  NextSegments = 5,
}

export class ScoreVector {
  values = new Vector(new Array(COEFFICIENTS.length).fill(0))

  constructor(vec?: ScoreVector) {
    const arr = vec ? vec.values.vec.slice(0) : new Array<number>(COEFFICIENTS.length).fill(0)
    this.values = new Vector(arr)
  }

  compare(other: ScoreVector): number {
    // 1. Vocal clash
    const thisVocals = this.get(ScoreField.Vocals)
    const otherVocals = other.get(ScoreField.Vocals)
    if (Math.abs(thisVocals - otherVocals) > 0.5) {
      return thisVocals > otherVocals ? 1 : -1
    }
    
    // 2. Key compatibility
    const thisKey = this.get(ScoreField.Key)
    const otherKey = other.get(ScoreField.Key)
    if (Math.abs(thisKey - otherKey) > 0.5) {
      return thisKey > otherKey ? 1 : -1
    }

    const thisMean = this.weightedMean()
    const otherMean = other.weightedMean()
    return thisMean > otherMean ? 1 : -1
  }

  weightedMean(): number {
    return this.values.mul(COEFFICIENTS).mean()
  }

  get(field: ScoreField): number {
    return this.values.vec[field]
  }

  set(field: ScoreField, value: number): ScoreVector {
    this.values.vec[field] = value
    return this
  }
}

export default ScoreVector
