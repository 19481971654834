import React from 'react'
import { MouseEvent } from 'react'
import './ToggleButton.css'

type Props = {
  text: String
  onChange: (on: boolean) => void
  enabled?: boolean
  style?: {[key: string]: any}
}

type State = {
  enabled: boolean
}

export default class ToggleButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { enabled: props.enabled || false }
  }

  setEnabled(enabled: boolean) {
    this.setState({ enabled: enabled }, () => {
      this.props.onChange(enabled)
    })
  }

  handleClick = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    this.setEnabled(!this.state.enabled)
  }

  render() {
    const onOff = this.state.enabled ? 'On' : 'Off'
    return (
      <div className="ToggleButton" style={this.props.style}>
        <div className={`OnOffLight ${onOff}`}></div>
        <div className="Text">{this.props.text}</div>
        <button onClick={this.handleClick}>&nbsp;</button>
      </div>
    )
  }
}
