import React from 'react'
import './Sequencer.css'

const CHANNELS = 4
const BARS = 4

type Props = {
  playing: boolean
  beat: number
}

export default class Sequencer extends React.Component<Props, {}> {
  render() {
    const bar = Math.floor(this.props.beat / 4) % BARS
    const items = []
    for (let i = 0; i < CHANNELS; i++) {
      for (let j = 0; j < BARS; j++) {
        const className = this.props.playing && bar === j ? 'Current' : ''
        const key = `button-${i}-${j}`
        items.push(<button className={className} key={key} />)
      }
      items.push(<br key={`br-${i}`} />)
    }

    return <div className="Sequencer">{items}</div>
  }
}
