import log from 'loglevel'

enum DeviceMode {
  General = 0x40,
  Ableton = 0x41,
  AbletonAlt = 0x42,
}

enum RingType {
  Off = 0,
  Single = 1,
  Volume = 2,
  Pan = 3,
}

export default class APC40MkII {
  enabled: boolean
  ready = false
  out?: WebMidi.MIDIOutput

  constructor() {
    this.enabled = navigator.requestMIDIAccess !== undefined
  }

  async start() {
    this.stop()
    log.debug(`WebMIDI ${this.enabled ? 'supported' : 'unsupported'}`)
    if (!this.enabled) return false

    const access = await navigator.requestMIDIAccess({ sysex: true })
    if (!access.sysexEnabled) {
      log.error(`WebMIDI sysex not enabled`)
      this.enabled = false
      return false
    }

    if (!access.outputs.size) {
      log.info(`No MIDI devices found`)
      return false
    }

    for (let [id, dev] of access.outputs) {
      log.debug(
        `[MIDI DEVICE] ${id} ${dev.manufacturer} - ${dev.name}${
          dev.version ? ` (${dev.version})` : ''
        }`
      )
      if (dev.manufacturer === 'Akai' && dev.name === 'APC40 mkII') {
        this.out = dev

        this.out.onstatechange = (e) => {
          log.info(`[APC40mkII] State changed to ${e.port.state}`)
          this.ready = e.port.state === 'connected'
          if (!this.ready) return
          this.setup()
        }
        this.out.open()
        return true
      }
    }

    return false
  }

  stop() {
    if (this.out?.state === 'connected') {
      this.setAllOff()
      this.out.close()
    }
    this.ready = false
    this.out = undefined
  }

  setup() {
    this.setDeviceMode(DeviceMode.AbletonAlt)
    this.setAllOff()

    const payload: number[] = []
    this._play(payload, true)
    this.out?.send(payload)
  }

  setDeviceMode(mode: DeviceMode) {
    this.out?.send([
      0xf0, // MIDI System exclusive message start
      0x47, // Manufacturers ID Byte
      0x7f, // System Exclusive Device ID
      0x29, // Product model ID
      0x60, // Message type identifier
      0x00, // Number of data bytes to follow (most significant)
      0x04, // Number of data bytes to follow (least significant)
      mode, // Application/Configuration identifier
      0x0a, // PC application Software version major
      0x01, // PC application Software version minor
      0x19, // PC Application Software bug-fix level
      0xf7, // MIDI System exclusive message terminator
    ])
    log.debug(`[APC40mkII] Device mode set to 0x${mode.toString(16)}`)
  }

  _play(payload: number[], on: boolean) {
    payload.push(on ? 0x90 : 0x80, 0x5b, on ? 1 : 0)
  }

  setGrid(colors: Uint8Array, timestamp?: number) {
    if (colors.length !== 40) throw new Error(`Invalid color grid length ${colors.length}`)

    const payload: number[] = []
    for (let y = 0; y < 5; y++) {
      for (let x = 0; x < 8; x++) {
        const c = colors[y * 8 + x]
        this._ledOn(payload, x, y, c)
      }
    }
    this.out?.send(payload, timestamp)
  }

  setTrackButtons(values: Uint8Array, timestamp?: number) {
    if (values.length !== 32) throw new Error(`Invalid values length ${values.length}`)

    const payload: number[] = []
    for (let ch = 0; ch < 8; ch++) {
      payload.push(0x90 | ch, 0x31, values[ch * 4 + 0])
      payload.push(0x90 | ch, 0x32, values[ch * 4 + 1])
      payload.push(0x90 | ch, 0x42, values[ch * 4 + 2])
      payload.push(0x90 | ch, 0x30, values[ch * 4 + 3])
    }
    this.out?.send(payload, timestamp)
  }

  setDeviceRings(values: Uint8Array, timestamp?: number) {
    if (values.length !== 8) throw new Error(`Invalid values length ${values.length}`)

    const payload: number[] = []
    for (let i = 0; i < 8; i++) {
      this._setDeviceRing(payload, i, values[i])
    }
    this.out?.send(payload, timestamp)
  }

  setAllOff(timestamp?: number) {
    if (!this.out) return

    const payload = []
    for (let n = 0; n < 0x60; n++) {
      payload.push(0x80, n, 0)
    }
    for (let ch = 0; ch < 8; ch++) {
      payload.push(0x80 | ch, 0x30, 0)
      payload.push(0x80 | ch, 0x31, 0)
      payload.push(0x80 | ch, 0x32, 0)
      payload.push(0x80 | ch, 0x42, 0)
    }
    for (let i = 0; i < 8; i++) {
      this._setupDeviceRing(payload, i, RingType.Volume)
      this._setDeviceRing(payload, i, 0)
    }
    this.out.send(payload, timestamp)
  }

  _ledOn(payload: number[], x: number, y: number, color: number) {
    if (x < 0 || x > 7) throw new Error(`Invalid x ${x}`)
    if (y < 0 || y > 4) throw new Error(`Invalid y ${y}`)
    if (color < 0 || color > 127) throw new Error(`Invalid color ${color}`)

    payload.push(0x90, (4 - y) * 8 + x, color)
  }

  _ledOff(payload: number[], x: number, y: number) {
    if (x < 0 || x > 7) throw new Error(`Invalid x ${x}`)
    if (y < 0 || y > 4) throw new Error(`Invalid y ${y}`)

    payload.push(0x80, (4 - y) * 8 + x, 0)
  }

  _setupDeviceRing(payload: number[], index: number, type: RingType) {
    payload.push(0xb0, 0x18 + index, type)
  }

  _setDeviceRing(payload: number[], index: number, value: number) {
    payload.push(0xb0, 0x10 + index, value)
  }
}
