import './Player.css'
import { FormatTime } from './mixer/Conversions'
import { PlayTrack } from './mixer/Timeline'
import Clock from './mixer/Clock'
import Controller from './mixer/Controller'
import Deck from './Deck'
import log from 'loglevel'
import MidiController from './midi/MidiController'
import NoSleep from 'nosleep.js'
import React from 'react'
import Sequencer from './Sequencer'
import ToggleButton from './ToggleButton'

// const LIBRARY_URL = 'https://infinitegarden.s3.us-west-2.amazonaws.com/hr2020sm/library.json'
const LIBRARY_URL = 'https://infinitegarden.s3.us-west-2.amazonaws.com/dnb001/library.json'

let noSleep: NoSleep

type State = {
  error?: Error
  loaded: boolean
  playing: boolean
  tick: number
  time: number
  bpm: number
}

function DisplayTime(time: number, entry?: PlayTrack) {
  if (!entry) return 0
  if (entry.startTime !== undefined && time >= entry.startTime)
    return time - entry.startTime + entry.offsetSec
  return entry.offsetSec
}

function DisplayProgress(time: number, entry?: PlayTrack) {
  if (!entry) return 0
  if (entry.startTime !== undefined && time > entry.startTime)
    return (time - entry.startTime + entry.offsetSec) / entry.track.metadata.duration
  return entry.offsetSec / entry.track.metadata.duration
}

export default class Player extends React.Component<{}, State> {
  controller: Controller
  midi: MidiController
  playButton: React.RefObject<ToggleButton>
  frameId = 0

  constructor(props: {}) {
    super(props)

    log.enableAll()

    this.controller = new Controller()
    this.midi = new MidiController(this.controller)
    this.playButton = React.createRef()
    this.state = { loaded: false, playing: false, tick: 0, time: 0, bpm: 0 }
  }

  async componentDidMount() {
    if (!this.frameId) {
      this.frameId = window.requestAnimationFrame(this.animationFrame)
    }

    await this.controller.load(LIBRARY_URL)

    this.setState({ loaded: true, bpm: this.controller.bpm })
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.frameId)
  }

  startStop = async (on: boolean) => {
    ;(on ? this.play : this.stop)()
  }

  play = async () => {
    // Activate wake lock
    if (!noSleep) {
      noSleep = new NoSleep()
      const ns = noSleep as any
      if (ns.noSleepVideo) ns.noSleepVideo.setAttribute('title', 'The Infinite Garden')
    }
    noSleep.enable()

    if (this.state.playing) this.controller.stop()
    await this.controller.start()
    await this.midi.start()

    this.setState({ playing: true, tick: 0, time: 0 })
  }

  stop = async () => {
    if (!this.state.playing) return
    this.controller.stop()
    this.midi.stop()

    this.setState({ playing: false, tick: 0, time: 0 })

    noSleep.disable()
  }

  animationFrame = () => {
    const { tick, time, bpm } = this.controller
    this.setState({ tick, time, bpm })
    this.midi.render(tick)
    this.frameId = window.requestAnimationFrame(this.animationFrame)
  }

  render() {
    const { error, loaded } = this.state
    if (error) {
      return (
        <div className="Player">
          <div className="status error">Error: {error.message}</div>
        </div>
      )
    } else if (!loaded) {
      return (
        <div className="Player">
          <div className="status">Loading...</div>
        </div>
      )
    }

    const playBtnStyle = {
      position: 'absolute',
      top: '10px',
      right: '10px',
    }

    const { tick, time, bpm } = this.state
    const beat = Clock.TickToBeat(tick)
    const play = this.controller.running
      ? this.controller.timeline.activeTrack(beat)
      : undefined
    const trackTime = DisplayTime(time, play)
    const progress = DisplayProgress(time, play)
    const tempoStr = bpm.toFixed(2).padStart(5, '0')
    const beatStr = beat.toString().padStart(4, '0')

    return (
      <div className="Player">
        <div className="HeaderTable">
          <div className="HeaderRow">
            <span className="Time">{FormatTime(this.state.time)}</span>
            <span className="Beat">{beatStr}</span>
          </div>
          <div className="HeaderRow">
            <span className="Tempo">{tempoStr}</span>
          </div>
          <div className="Time"></div>
          <div className="Tempo"></div>
        </div>
        <ToggleButton
          ref={this.playButton}
          text="PLAY"
          enabled={this.state.playing}
          onChange={this.startStop}
          style={playBtnStyle}
        />
        <Sequencer playing={this.state.playing} beat={beat} />
        <Deck
          time={trackTime}
          progress={progress}
          currentMix={this.controller.dj.mix}
          mixCandidates={this.controller.dj.mixCandidates}
          onSelectMix={this.handleSelectMix}
        />
      </div>
    )
  }

  handleSelectMix = (candidateIndex: number) => {
    // log.debug(`Mix ${candidateIndex} selected`)
    this.controller.dj.candidateIndex = candidateIndex
  }
}
