import { Point } from './Timeline'
import { Track } from './Track'
import ScoreVector from './ScoreVector'

export enum MixType {
  DoubleDrop = 'DoubleDrop',
  Rolling = 'Rolling',
  Chill = 'Chill',
}

export type TransitionPrimary = {
  bar: number
  fadeInBars: number
  fadeOutBars: number
  fadeType: MixType
}

export type TransitionSecondary = {
  bar: number
  fadeInBars: number
}

export type Transition = {
  prevTrack?: Track // Previous track to fade out of
  track: Track // Next track to fade in
  primaryBar: number // Bar of the current playing track to start the crossfade
  secondaryBar: number // Bar of the incoming track to start the crossfade
  fadeInBars: number // Bar count of the fade in section of the crossfade
  fadeOutBars: number // Bar count of the fade out section of the crossfade
  fadeType: MixType // Category of this crossfade
  semitoneOffset: number // Number of semitones to pitch shift the incoming track
  vocalClash: boolean // Whether there is a vocal clash in this transition or not
  score: ScoreVector // Transition scoring metrics
}

export class TransitionProfile {
  gain: Point[]
  low: Point[]
  high: Point[]

  constructor(bars: number[], gain: number[], low: number[], high: number[]) {
    if (gain.length !== bars.length) throw new Error(`gain/bars mismatch`)
    if (low.length !== bars.length) throw new Error(`low/bars mismatch`)
    if (high.length !== bars.length) throw new Error(`high/bars mismatch`)
    if (bars[0] !== 0) throw new Error(`bars must start at zero`)

    this.gain = bars.map((bar, i) => new Point(bar * 4, gain[i]))
    this.low = bars.map((bar, i) => new Point(bar * 4, low[i]))
    this.high = bars.map((bar, i) => new Point(bar * 4, high[i]))
  }
}

export class Crossfade {
  constructor(
    public outro: TransitionProfile,
    public intro: TransitionProfile,
    public transition: Transition
  ) {}

  static Create(transition: Transition) {
    const fadeType = transition.fadeType
    const C = transition.fadeInBars
    const L = transition.fadeInBars + transition.fadeOutBars
    if (C < 0) throw new Error(`Invalid crossoverBar ${C}`)
    if (L <= 0) throw new Error(`Invalid barCount ${L}`)

    let outro: TransitionProfile
    let intro: TransitionProfile

    if (C < 2 || L - C < 2) {
      // The intro/outro is short, create a simple crossfade
      const bars = [0, C, L]
      outro = new TransitionProfile(bars, EqualPowerOut(3), [1.0, 0.0, -1.0], [1.0, 0.0, -1.0])
      intro = new TransitionProfile(bars, EqualPowerIn(3), [-1.0, 0.0, 1.0], [-1.0, 0.0, 1.0])
    } else if (fadeType === MixType.Rolling) {
      // Rolling
      const bars = [0, 1, Math.floor(C / 2), C, C + 1, C + Math.floor((L - C) / 2), L]
      const pct = bars.map(x => x / L)
      outro = new TransitionProfile(
        bars,
        EqualPowerOut(pct),
        EqualPowerEqOut(pct),
        [1.0, 1.0, 1.0, 1.0, 0.2, -1.0, -1.0]
      )
      intro = new TransitionProfile(
        bars,
        EqualPowerIn(pct),
        EqualPowerEqIn(pct),
        [-1.0, -1.0, -1.0, 0.2, 1.0, 1.0, 1.0]
      )
    } else if (fadeType === MixType.DoubleDrop) {
      // DoubleDrop
      const bars = [0, 1, Math.floor(C / 2), C, C + 1, C + Math.floor((L - C) / 2), L]
      outro = new TransitionProfile(
        bars,
        [1.0, 1.0, 1.0, 1.0, 0.8, 0.8, 0.0],
        [1.0, 1.0, 1.0, 1.0, -1.0, -1.0, -1.0],
        [1.0, 1.0, 1.0, 1.0, 0.2, -0.4, -1.0]
      )
      intro = new TransitionProfile(
        bars,
        [0.0, 0.2, 0.8, 0.8, 1.0, 1.0, 1.0],
        [-1.0, -1.0, -1.0, 0.0, 1.0, 1.0, 1.0],
        [-1.0, -1.0, -1.0, 0.2, 1.0, 1.0, 1.0]
      )
    } else {
      // Chill
      const bars = [0, Math.floor(C / 2), C, C + 1, C + Math.floor((L - C) / 2), L]
      const pct = bars.map(x => x / L)
      outro = new TransitionProfile(
        bars,
        EqualPowerOut(pct),
        EqualPowerEqOut(pct),
        EqualPowerEqOut(pct)
      )
      intro = new TransitionProfile(
        bars,
        EqualPowerIn(pct),
        EqualPowerEqIn(pct),
        EqualPowerEqIn(pct)
      )
    }

    return new Crossfade(outro, intro, transition)
  }
}

export function EqualPowerOut(samples: number | number[]) {
  if (!Array.isArray(samples)) {
    const count = samples
    samples = Array.from({ length: Math.round(count) }, (_, i) => count > 1 ? i / (count - 1) : 1)
  }
  if (!samples.length) return []
  const arr = samples.map(x => Math.cos(x * 0.5 * Math.PI))
  arr[0] = 1
  arr[arr.length - 1] = 0
  return arr
}

export function EqualPowerIn(samples: number | number[]) {
  if (!Array.isArray(samples)) {
    const count = samples
    samples = Array.from({ length: Math.round(count) }, (_, i) => count > 1 ? i / (count - 1) : 1)
  }
  if (!samples.length) return []
  const arr = samples.map(x => Math.cos((1.0 - x) * 0.5 * Math.PI))
  arr[0] = 0
  arr[arr.length - 1] = 1
  return arr
}

export function EqualPowerEqOut(samples: number | number[]) {
  return EqualPowerOut(samples).map(x => x * 2 - 1)
}

export function EqualPowerEqIn(samples: number | number[]) {
  return EqualPowerIn(samples).map(x => x * 2 - 1)
}
