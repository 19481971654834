import { Track } from './Track'

export class Timeline {
  entries: Entry[] = []
  channelEntries: ChannelEntry[][] = [[], [], [], []]

  add(entry: Entry) {
    this.entries.push(entry)
    this.entries.sort(CompareEntries)
    if (entry instanceof ChannelEntry) {
      if (entry instanceof PlayTrack) {
        for (let i = 0; i < this.channelEntries.length; i++) {
          const clipBeat = i === entry.channel ? entry.start : entry.fadeInEndBeat
          this._truncatePreviousTrack(clipBeat, this.channelEntries[i])
        }
      }

      const entries = this.channelEntries[entry.channel]
      entries.push(entry)
      entries.sort(CompareEntries)
    }
  }

  clear() {
    this.entries.length = 0
    for (const entries of this.channelEntries) {
      entries.length = 0
    }
  }

  popEvents(beat: number) {
    // Remove fully expired entries from this.channelEntries
    for (const chEntries of this.channelEntries) {
      let i = 0
      while (i < chEntries.length && chEntries[i].end < beat) {
        i++
      }
      chEntries.splice(0, i)
    }

    // Remove and return popped entries from this.entries
    let i = 0
    while (i < this.entries.length && this.entries[i].start <= beat) {
      i++
    }
    return this.entries.splice(0, i)
  }

  gain(beat: number, channel: number) {
    const entries = this.channelEntries[channel]
    let lastVolumeEntry

    for (const entry of entries) {
      if (entry instanceof Volume) {
        if (beat < entry.start) return entry.value.start.value
        if (beat >= entry.start && beat <= entry.end) return entry.value.valueAt(beat)
        lastVolumeEntry = entry
      }
    }

    return lastVolumeEntry?.value.end.value || 0
  }

  track(beat: number, channel: number) {
    const entries = this.channelEntries[channel]

    for (const entry of entries) {
      if (entry instanceof PlayTrack && entry.end > beat) {
        return entry
      }
    }

    return undefined
  }

  tracks(beat: number) {
    const output = []
    for (const entries of this.channelEntries) {
      for (const entry of entries) {
        if (entry instanceof PlayTrack && entry.end > beat) {
          output.push(entry)
          break
        }
      }
    }
    return output
  }

  activeTrack(beat: number) {
    const tracks = this.tracks(beat)
    if (!tracks.length) return undefined
    return this.tracks(beat).sort((a, b) => a.end - b.end)[0]
  }

  gainCurve(startBeat: number, stopBeat: number, channel: number): Range[] {
    const entries = this.channelEntries[channel]
    const curve = []

    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i]
      if (entry instanceof Volume && entry.end > startBeat && entry.start < stopBeat) {
        const start = entry.value.start
        const end = entry.value.end
        curve.push(new Range(new Point(start.beat, start.value), new Point(end.beat, end.value)))
      }
    }

    if (curve.length) {
      const first = curve[0]
      const last = curve[curve.length - 1]
      first.start.beat = Math.max(first.start.beat, startBeat)
      last.end.beat = Math.min(last.end.beat, stopBeat)
    }

    return curve
  }

  private _truncatePreviousTrack(beat: number, channelEntries: ChannelEntry[]) {
    for (let i = 0; i < channelEntries.length; i++) {
      const entry = channelEntries[i]
      if (entry instanceof PlayTrack) {
        if (entry.start >= beat) return
        if (entry.end > beat) entry.value.end.beat = beat
      }
    }
  }

  private _search(beat: number, entries: Entry[]) {
    let m = 0
    let n = entries.length - 1
    while (m <= n) {
      const k = (n + m) >> 1
      const cmp = CompareBeat(beat, entries[k].value)
      if (cmp > 0) {
        m = k + 1
      } else if (cmp < 0) {
        n = k - 1
      } else {
        return k
      }
    }
    return -m - 1
  }
}

export class Point {
  constructor(public beat: number, public value: number) {}
}

export class Range {
  constructor(public start: Point, public end: Point) {}

  get beat() {
    return this.start.beat
  }

  get delta() {
    return this.end.value - this.start.value
  }

  get beats() {
    return this.end.beat - this.start.beat
  }

  valueAt(beat: number) {
    const beats = this.beats
    if (beats === 0 || beat >= this.end.beat) return this.end.value
    if (beat <= this.start.beat) return this.start.value

    const a = this.start.value
    const b = this.end.value
    const t = (beat - this.start.beat) / beats
    return a + (b - a) * t
  }

  static Make(start: number, end: number, startVal: number, endVal: number) {
    return new Range(new Point(start, startVal), new Point(end, endVal))
  }
}

export abstract class Entry {
  value: Point | Range
  startTime?: number

  get start() {
    if (this.value instanceof Point) return (this.value as Point).beat
    return (this.value as Range).start.beat
  }

  get end() {
    if (this.value instanceof Point) return (this.value as Point).beat
    return (this.value as Range).end.beat
  }

  constructor(value: Point | Range) {
    this.value = value
  }
}

export class ChannelEntry extends Entry {
  channel: number
  value: Range

  constructor(channel: number, start: number, end: number, startValue: number, endValue: number) {
    const range = Range.Make(start, end, startValue, endValue)
    super(range)
    this.value = range
    this.channel = channel
  }
}

export class PlayTrack extends ChannelEntry {
  track: Track
  fadeInEndBeat: number
  offsetBeat: number
  offsetSec: number
  semitoneOffset: number

  constructor(
    channel: number,
    start: number,
    fadeInEnd: number,
    end: number,
    track: Track,
    offsetBeat: number,
    offsetSec: number,
    semitoneOffset: number
  ) {
    super(channel, start, end, 0, 0)
    this.track = track
    this.fadeInEndBeat = fadeInEnd
    this.offsetBeat = offsetBeat
    this.offsetSec = offsetSec
    this.semitoneOffset = semitoneOffset
  }
}

export class PlayRate extends ChannelEntry {}

export class Volume extends ChannelEntry {}

export class High extends ChannelEntry {}

export class Low extends ChannelEntry {}

function CompareEntries(entry1: Entry, entry2: Entry) {
  if (entry1.start > entry2.start) return 1
  else if (entry1.start < entry2.start) return -1
  return 0
}

function CompareBeat(beat1: number, value2: Point | Range) {
  const beat2 = value2.beat
  if (beat1 > beat2) return 1
  else if (beat1 < beat2) return -1
  return 0
}
