import log from 'loglevel'
import React from 'react'
import { Point } from './mixer/Timeline'
import { Track, TrackMetadata } from './mixer/Track'
import { FormatTime } from './mixer/Conversions'
import { Crossfade } from './mixer/Crossfade'
import './Deck.css'

type Props = {
  time: number
  progress: number
  currentMix?: Crossfade
  mixCandidates: Crossfade[]
  onSelectMix: (candidateIndex: number) => void
}

function ClipPath(gainCurve: [number, number][], invert: boolean) {
  let path = 'polygon('
  for (let i = 0; i < gainCurve.length; i++) {
    const entry = gainCurve[i]
    const [x, y] = entry
    path += `${i > 0 ? ', ' : ''}${x * 100}% ${(invert ? y : 1 - y) * 100}%`
  }
  path += ')'
  return path
}

function GainCurve(startBeat: number, curve?: Point[], track?: Track): [number, number][] {
  if (!curve || !track) return []
  const beats = track.metadata.beats.length
  const startValue = curve[0].value
  const lastValue = curve[curve.length - 1].value
  const fP = (p: Point): [number, number] => [(startBeat + p.beat) / beats, p.value]
  return [[0, 0], [0, startValue], ...curve.map(fP), [1, lastValue], [1, 0]]
}

function SegmentElements(metadata?: TrackMetadata) {
  return (
    metadata?.segments.map((segment, i) => {
      const className = segment.type === 'L' ? 'TrackSegment Low' : 'TrackSegment High'
      const startTime = metadata.downbeats[segment.bar]
      const endTime =
        i < metadata.segments.length - 1
          ? metadata.downbeats[metadata.segments[i + 1].bar]
          : metadata.duration
      const pct = (endTime - startTime) / metadata.duration
      const style = { width: `${pct * 100}%` }
      return <span className={className} key={`segment-${i}`} style={style} />
    }) || []
  )
}

export default class Deck extends React.Component<Props, {}> {
  scrollList = React.createRef<HTMLDivElement>()
  candidateIndex = 0
  resetScroll = false

  shouldComponentUpdate(nextProps: Readonly<Props>) {
    if (this.props.mixCandidates !== nextProps.mixCandidates) this.resetScroll = true
    return true
  }

  componentDidUpdate() {
    if (this.resetScroll) {
      log.info(`Resetting selected mix`)
      this.resetScroll = false
      this.candidateIndex = 0
      this.scrollList.current!.scrollTop = 0
    }
  }

  render() {
    const curMetadata = this.props.currentMix?.transition.track.metadata
    const cursorStyle = { left: `${this.props.progress * 100}%` }
    const curSegments = SegmentElements(curMetadata)

    const mixCandidates = this.props.mixCandidates.map((mix) => {
      const t = mix.transition
      const key = `${t.track.name}-${t.fadeType}-${t.primaryBar}-${t.secondaryBar}`
      const metadata = t.track.metadata
      const gainCurve = GainCurve(0, mix.intro.gain, mix.transition.track)
      const prevStartBeat = (mix.transition.primaryBar - mix.transition.fadeOutBars) * 4
      const prevGainCurve = GainCurve(prevStartBeat, mix.outro.gain, mix.transition.prevTrack)
      const offsetBeat = (t.primaryBar - t.fadeInBars) * 4
      const prevTrack = t.prevTrack!
      const offset = prevTrack.metadata.beats[offsetBeat] / prevTrack.metadata.duration
      const prevVizStyle = { clipPath: ClipPath(prevGainCurve, false) }
      const vizStyle = {
        left: `${offset * 100}%`,
        clipPath: ClipPath(gainCurve, true),
      }
      const segments = SegmentElements(metadata)
      return (
        <div className="ListItem" key={key}>
          <div className="DeckRow VizRow">
            <div className="Viz-A" style={prevVizStyle}>
              {curSegments}
            </div>
            <div className="Viz-B" style={vizStyle}>
              {segments}
            </div>
          </div>
          <div className="DeckRow Row-B">
            <span className="Title">{metadata?.title || ''}</span>
          </div>
          <div className="DeckRow Row-B">
            <span className="Artist">{metadata?.artist || ''}</span>
          </div>
        </div>
      )
    })

    return (
      <div className="Deck">
        <div className="DeckRow">
          <span className="Title">{curMetadata?.title || ''}</span>
          <span className="Tempo">{(curMetadata?.tempo || 0).toFixed(2)}</span>
        </div>
        <div className="DeckRow">
          <span className="Artist">{curMetadata?.artist || ''}</span>
          <span className="Time">{`${FormatTime(this.props.time)}`}</span>
        </div>
        <div className="DeckRow VizRow">
          <span className="Cursor" style={cursorStyle} />
        </div>
        <div className="ScrollableList" ref={this.scrollList} onScroll={this.handleScroll}>
          {mixCandidates}
        </div>
      </div>
    )
  }

  handleScroll = (ev: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const ITEM_HEIGHT = 120

    const pos = ev.currentTarget.scrollTop
    const idx = Math.min(Math.round(pos / ITEM_HEIGHT), this.props.mixCandidates.length - 1)
    if (idx === this.candidateIndex) return

    this.candidateIndex = idx
    this.props.onSelectMix(this.candidateIndex)
  }
}
