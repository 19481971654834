import log from 'loglevel'

type TimelineEntry = { tick: number; value: number }

function Clamp(x: number, lower: number, upper: number) {
  return Math.min(Math.max(x, lower), upper)
}

function Lerp(a: number, b: number, t: number) {
  return a + (b - a) * t
}

// A numeric value that is a piecewise linear function of time, quantized into
// Pulses Per Quarter-Note (PPQ, or "ticks")
export default class TemporalValue {
  timeline: TimelineEntry[]

  constructor(value: number) {
    this.timeline = [{ tick: 0, value }]
  }

  clear() {
    this.timeline.splice(1)
  }

  clearBefore(tick: number) {
    if (this.timeline.length < 2 || this.timeline[1].tick >= tick) return
    this.timeline = this.timeline.filter((a, i) => i === 0 || a.tick >= tick)
  }

  setValueAt(tick: number, value: number) {
    this.setValuesAt([{ tick, value }])
  }

  setValuesAt(entries: [TimelineEntry]) {
    for (let entry of entries) {
      const lastTick = this.timeline[this.timeline.length - 1].tick
      if (lastTick === entry.tick) {
        this.timeline[this.timeline.length - 1] = entry
      } else if (lastTick < entry.tick) {
        this.timeline.push(entry)
      } else {
        log.error(`New entry tick ${entry.tick} is before ${lastTick}`)
      }
    }
  }

  valueAt(tick: number) {
    const size = this.timeline.length
    // Optimization: Requesting past the end of the timeline is a common case
    if (tick >= this.timeline[size - 1].tick) return this.timeline[size - 1].value

    for (let i = 1; i < size; i++) {
      if (this.timeline[i].tick >= tick) {
        const a = this.timeline[i - 1]
        const b = this.timeline[i]
        const t = Clamp((tick - a.tick) / (b.tick - a.tick), 0, 1)
        return Lerp(a.value, b.value, t)
      }
    }

    return this.timeline[size - 1].value
  }

  prevIndex(tick: number) {
    for (let i = 1; i < this.timeline.length; i++) {
      if (this.timeline[i].tick > tick) return i - 1
    }
    return this.timeline.length - 1
  }
}
