
function RandomSeedParkMiller(seed: number): () => number {
  // https://gist.github.com/blixt/f17b47c62508be59987b
  if (seed === 0) seed = 127773
  seed = Math.abs((seed * 16807) % 2147483646) + 1
  return () => {
    seed = (seed * 16807) % 2147483647
    return (seed - 1) / 2147483646
  }
}

export default class PRNG {
  generator: () => number

  constructor(seed?: number) {
    this.generator = RandomSeedParkMiller(seed !== undefined ? seed : Date.now())
  }

  setSeed(seed: number) {
    this.generator = RandomSeedParkMiller(seed)
  }

  next() {
    return this.generator()
  }

  nextArrayItem<T>(array: T[]): T {
    return array[Math.floor(this.generator() * array.length)]
  }
}
