export enum Key {
  A = 'A:major',
  Bb = 'Bb:major',
  B = 'B:major',
  C = 'C:major',
  Cs = 'C#:major',
  D = 'D:major',
  Eb = 'Eb:major',
  E = 'E:major',
  F = 'F:major',
  Fs = 'F#:major',
  G = 'G:major',
  Ab = 'Ab:major',
  Am = 'A:minor',
  Bbm = 'Bb:minor',
  Bm = 'B:minor',
  Cm = 'C:minor',
  Csm = 'C#:minor',
  Dm = 'D:minor',
  Ebm = 'Eb:minor',
  Em = 'E:minor',
  Fm = 'F:minor',
  Fsm = 'F#:minor',
  Gm = 'G:minor',
  Abm = 'Ab:minor',
}

export const CIRCLE_OF_FIFTHS_MAJOR = [
  Key.C,
  Key.G,
  Key.D,
  Key.A,
  Key.E,
  Key.B,
  Key.Fs,
  Key.Cs,
  Key.Ab,
  Key.Eb,
  Key.Bb,
  Key.F,
]

export const CIRCLE_OF_FIFTHS_MINOR = [
  Key.Am,
  Key.Em,
  Key.Bm,
  Key.Fsm,
  Key.Csm,
  Key.Abm,
  Key.Ebm,
  Key.Bbm,
  Key.Fm,
  Key.Cm,
  Key.Gm,
  Key.Dm,
]

export const NOTES_MAJOR = [
  Key.C,
  Key.Cs,
  Key.D,
  Key.Eb,
  Key.E,
  Key.F,
  Key.Fs,
  Key.G,
  Key.Ab,
  Key.A,
  Key.Bb,
  Key.B,
]

export const NOTES_MINOR = [
  Key.Cm,
  Key.Csm,
  Key.Dm,
  Key.Ebm,
  Key.Em,
  Key.Fm,
  Key.Fsm,
  Key.Gm,
  Key.Abm,
  Key.Am,
  Key.Bbm,
  Key.Bm,
]

export const MAJOR_KEYS_SET = new Set(NOTES_MAJOR)

function ModFloor(a: number, n: number) {
  return ((a % n) + n) % n
}

export function IsMajorKey(key: Key) {
  return MAJOR_KEYS_SET.has(key)
}

export function ChangeScale(key: Key) {
  return IsMajorKey(key)
    ? NOTES_MINOR[NOTES_MAJOR.indexOf(key)]
    : NOTES_MAJOR[NOTES_MINOR.indexOf(key)]
}

/// Returns the key at key + offset around the circle of fifths
export function GetKey(key: Key, offset: number, switchMajorMinor = false) {
  if (offset === 0 && !switchMajorMinor) return key
  const isMajor = IsMajorKey(key)
  let circle = isMajor ? CIRCLE_OF_FIFTHS_MAJOR : CIRCLE_OF_FIFTHS_MINOR
  const index = ModFloor(circle.indexOf(key) + offset, 12)
  if (switchMajorMinor) circle = isMajor ? CIRCLE_OF_FIFTHS_MINOR : CIRCLE_OF_FIFTHS_MAJOR
  return circle[index]
}

export function GetKeyTransposed(key: Key, semitones: number) {
  if (semitones === 0) return key
  const notes = IsMajorKey(key) ? NOTES_MAJOR : NOTES_MINOR
  const index = notes.indexOf(key)
  return notes[ModFloor(index + semitones, 12)]
}

export function GetRelativeKey(key: Key) {
  return ChangeScale(IsMajorKey(key) ? GetKeyTransposed(key, -3) : GetKeyTransposed(key, 3))
}

export function GetCloselyRelatedKeys(key: Key) {
  return [
    key,
    GetRelativeKey(key),
    GetKeyTransposed(key, 7), // Perfect fifth up
    GetKeyTransposed(key, -7), // Perfect fifth down
  ]
}

export function GetVaguelyRelatedKeys(key: Key) {
  return [
    GetKey(key, IsMajorKey(key) ? 1 : -1, true), // Diagonal
  ]
}

export function DistanceKeysSemitones(key1: Key, key2: Key) {
  const i1 = IsMajorKey(key1) ? NOTES_MAJOR.indexOf(key1) : NOTES_MINOR.indexOf(key1)
  let i2 = IsMajorKey(key2) ? NOTES_MAJOR.indexOf(key2) : NOTES_MINOR.indexOf(key2)

  const cw = i1 > i2 ? 12 - i1 + i2 : i2 - i1
  const ccw = i1 > i2 ? i1 - i2 : 12 - i2 + i1
  return ccw < cw ? ccw * -1 : cw
}

// A positive distance is returned for clockwise rotations, and negative for counter-clockwise
export function DistanceKeysCircleOfFifths(key1: Key, key2: Key) {
  const i1 = IsMajorKey(key1)
    ? CIRCLE_OF_FIFTHS_MAJOR.indexOf(key1)
    : CIRCLE_OF_FIFTHS_MINOR.indexOf(key1)
  const i2 = IsMajorKey(key2)
    ? CIRCLE_OF_FIFTHS_MAJOR.indexOf(key2)
    : CIRCLE_OF_FIFTHS_MINOR.indexOf(key2)
  return ((6 + ((i2 - i1) % 12)) % 12) - 6
}
