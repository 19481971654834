type FloatValues = number[] | Float32Array

export default class Vector {
  vec: FloatValues

  constructor(values: FloatValues) {
    this.vec = Array.isArray(values) ? new Float32Array(values) : values
  }

  get length() {
    return this.vec.length
  }

  slice(start?: number, end?: number) {
    if (Array.isArray(this.vec)) return new Vector(this.vec.slice(start, end))
    else return new Vector(this.vec.subarray(start, end))
  }

  add(x: Vector | number) {
    if (x instanceof Vector) return new Vector(this.vec.map((v: number, i: number) => v + x.vec[i]))
    else return new Vector(this.vec.map((v: number) => v + x))
  }

  mul(x: Vector | number) {
    if (x instanceof Vector) return new Vector(this.vec.map((v: number, i: number) => v * x.vec[i]))
    else return new Vector(this.vec.map((v: number) => v * x))
  }

  div(x: Vector | number) {
    if (x instanceof Vector) return new Vector(this.vec.map((v: number, i: number) => v / x.vec[i]))
    else return new Vector(this.vec.map((v: number) => v / x))
  }

  distSq(x: Vector) {
    let sum = 0.0
    for (let i = 0; i < this.vec.length; i++) {
      const diff = this.vec[i] - x.vec[i]
      sum += diff * diff
    }
    return sum
  }

  dist(x: Vector) {
    return Math.sqrt(this.distSq(x))
  }

  greater(x: number) {
    return new Vector(this.vec.map((v: number) => (v > x ? 1 : 0)))
  }

  greaterEqual(x: number) {
    return new Vector(this.vec.map((v: number) => (v >= x ? 1 : 0)))
  }

  logicalAnd(x: Vector) {
    return new Vector(this.vec.map((v: number, i: number) => (v && x.vec[i] ? 1 : 0)))
  }

  sum() {
    if (Array.isArray(this.vec)) return this.vec.reduce((sum, x) => sum + x, 0.0)
    else return this.vec.reduce((sum, x) => sum + x, 0.0)
  }

  mean() {
    return this.sum() / this.length
  }
}
